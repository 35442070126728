import * as api from '../api';
import jwt from 'jsonwebtoken';

export const isBrowser = () => typeof window !== 'undefined';

class Auth {
  user;
  token;

  constructor() {
    this.getUser();
  }

  getUser = () => {
    if (!isBrowser()) {
      return null;
    }

    if (this.user) {
      return this.user;
    }

    const token = window.localStorage.getItem('mbeToken');
  
    const infos = jwt.decode(token);

    if (!infos) {
      return null;
    }

    this.token = token;
    this.user = infos;
  }

  setToken = (token) => {
    this.user = null;
    window.localStorage.setItem('mbeToken', token);
    this.getUser();
  }

  sendMagicLink = async (email, captcha, locale) => {
    console.log('**** sendMagicLink');
    try {
      return await api.sendMagicLink(email, captcha, locale);
    } catch (e) {
      this.setToken(null);
      let error = e;
      
      if (e.response && e.response.data && e.response.data.type) {
        error = e.response.data.type;
      }
      throw new Error(error);
    }
  };

  register = async (email, team, code) => {
    try {
      const resp = await api.register(email, team, code);
      this.setToken(resp.token);
      return resp;
    } catch (e) {
      this.setToken(null);
      let error = e;
      
      if (e.response && e.response.data && e.response.data.type) {
        error = e.response.data.type;
      }
      throw new Error(error);
    }
  }

  login = async (email, code) => {
    try {
      const resp = await api.login(email, code);
      this.setToken(resp.token);
      return resp;
    } catch (e) {
      this.setToken(null);
      let error = e;
      
      if (e.response && e.response.data && e.response.data.type) {
        error = e.response.data.type;
      }
      throw new Error(error);
    }
  };

  fastlogin = async (email, locale) => {
    try {
      const resp = await api.fastlogin(email, locale);
      this.setToken(resp.token);
      return resp;
    } catch (e) {
      this.setToken(null);
      let error = e;
      
      if (e.response && e.response.data && e.response.data.type) {
        error = e.response.data.type;
      }
      throw new Error(error);
    }
  };

  isLoggedIn = () => {
    const user = this.getUser();
    return !!user;
  };
}

export default new Auth();
