import React, { useEffect } from 'react';
import { navigate } from 'gatsby-plugin-intl';
import auth from './auth';

const PrivateRoute = WrappedComponent => props => {
  if (!auth.isLoggedIn()) {
    useEffect(() => {
      navigate('/login');
    }, []);

    return <div />;
  }

  return <WrappedComponent {...props} />;
};
export default PrivateRoute;
