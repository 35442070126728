import axios from 'axios';

const API_DOMAIN = process.env.GATSBY_API_DOMAIN;
const NODE_ENV = process.env.NODE_ENV;

const isBrowser = () => typeof window !== 'undefined';
const getSubdomain = () => {
  if (!isBrowser) {
    return null;
  }

  if (NODE_ENV !== 'production') {
    return 'breaking';
  }

  return /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.').shift();
}

export const sendMagicLink = async (email, captcha, locale) => {
  const result = await axios.post(`${API_DOMAIN}/auth/magiclink`, {
    email,
    // 'g-recaptcha-response': captcha,
    subdomain: getSubdomain(),
    locale,
  });

  console.log('[API] sendMagicLink', result.data);
  return result.data;
}

export const register = async (email, team, code) => {
  const result = await axios.post(`${API_DOMAIN}/auth/register`, {
    team,
    email,
    subdomain: getSubdomain(),
    code,
  });

  return result.data;
}

export const login = async (email, code) => {
  const result = await axios.post(`${API_DOMAIN}/auth/login`, {
    email,
    subdomain: getSubdomain(),
    code,
  });

  return result.data;
}

export const fastlogin = async (email, locale) => {
  const result = await axios.post(`${API_DOMAIN}/auth/fastlogin`, {
    email,
    locale,
  });

  return result.data;
}